import { RemixBrowser } from "@remix-run/react";
import { hydrateRoot } from "react-dom/client";
import { cacheAssets } from "remix-utils/cache-assets";

cacheAssets().catch(error => {
  // do something with the error, or not
});

hydrateRoot(document, <RemixBrowser />);

